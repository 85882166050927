var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-col',{staticClass:"px-0",attrs:{"cols":"12"}},[_c('v-row',{staticClass:"px-2 extra-container"},[_c('v-col',{staticClass:"font-weight-medium text-subtitile-1 text-black text-lighten-2",attrs:{"cols":"7"}},[_c('div',[_vm._v(_vm._s(_vm.extra.name))]),_c('div',{staticClass:"font-weight-regular stock-text"},[_vm._v(" "+_vm._s(_vm.hasReachedMaxStock || _vm.hasNegativeStock ? " (Stock Agotado)" : "")+" ")])]),_c('v-col',{staticClass:"secondary rounded-xl px-5 chip",attrs:{"cols":"5"}},[_c('v-row',{staticClass:"py-2",attrs:{"justify":"space-between","align":"center"}},[_c('v-btn',{attrs:{"disabled":_vm.optionQuantity <= _vm.extra.minQty,"icon":"","fab":"","x-small":"","elevation":"0"},on:{"click":function($event){_vm.optionQuantity--;
            _vm.$store.commit('currentProduct/REMOVE_OPTION', {
              name: _vm.extra.name,
              unitPrice: _vm.extra.price,
              sku: _vm.extra.sku,
              quantity: _vm.optionQuantity,
              optionsGroupsName: _vm.optionsGroupsName,
            });}}},[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-minus")])],1),_c('div',{staticClass:"px-1 text-h6",class:_vm.optionQuantity <= 0
              ? 'grey--text lighten-2 font-weight-regular'
              : 'font-weight-medium'},[_vm._v(" "+_vm._s(_vm.optionQuantity)+" ")]),_c('v-btn',{attrs:{"disabled":_vm.optionQuantity >= _vm.extra.maxQty ||
            _vm.disableMore ||
            _vm.hasToDisableButton ||
            _vm.hasReachedMaxStock ||
            _vm.hasNegativeStock,"color":"black","icon":"","fab":"","x-small":"","elevation":"0"},on:{"click":function($event){_vm.optionQuantity++;
            _vm.$store.commit('currentProduct/ADD_OPTION', {
              name: _vm.extra.name,
              unitPrice: _vm.extra.price,
              sku: _vm.extra.sku,
              quantity: _vm.optionQuantity,
              optionsGroupsName: _vm.optionsGroupsName,
            });}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)],1)],1),(_vm.showDivider)?_c('v-divider',{staticClass:"px-0 mt-8"}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
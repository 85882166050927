<template>
  <base-section space="80">
    <v-container class="fill-height px-10 py-6">
      <v-row>
        <!-- Cartel de que faltan los limites -->
        <div
          v-if="getOptionsText != ''"
          class="text-h6 font-weight-medium pb-5"
        >
          {{ getOptionsText }}
        </div>

        <!-- AVISO DE QUE SE LLEGO AL TOPE -->
        <div v-if="flavorLimit" class="text-h6 font-weight-medium pb-5">
          Alcanzaste el máximo de
          {{
            currentProductSelectedOptionsGroup.name === "Adicionales"
              ? "adicionales"
              : "gustos"
          }}
        </div>
        <v-col cols="12">
          <base-outlined-block
            v-if="
              currentProductSelectedOptionsGroup &&
              currentProductSelectedOptionsGroup.options &&
              currentProductSelectedOptionsGroup.options[0]
            "
          >
            <!-- SON TODOS LOS ADICIONALES O GUSTOS QUE PUEDE HABER -->
            <product-extra-single
              v-for="(
                extra, index
              ) in currentProductSelectedOptionsGroup.options"
              :extra="extra"
              :options-groups-name="currentProductSelectedOptionsGroup.name"
              :disable-more="disableMore"
              :show-divider="
                index + 1 < currentProductSelectedOptionsGroup.options.length
              "
              :existing-option-quantity="getCurrentProductQuantity(extra)"
              :key="index"
            />
          </base-outlined-block>
        </v-col>
      </v-row>

      <!-- <div>
        <h2>Test info:</h2>
        <h4>Pushed optionals:</h4>
        {{
          currentAddedProduct && currentAddedProduct.options
            ? currentAddedProduct.options
            : "Empty"
        }}
        <br />
        <br />
        <h4>Unpushed optionals (including existing):</h4>
        {{
          $store.state.currentProduct && $store.state.currentProduct.options[0]
            ? $store.state.currentProduct.options
            : "Empty"
        }}
      </div> -->
    </v-container>
  </base-section>
</template>

<script>
import getCurrentProduct from "@/mixins/getCurrentProduct";
import ProductExtraSingle from "@/components/ProductExtraSingle";

export default {
  mixins: [getCurrentProduct],

  provide: {
    heading: { align: "center" },
  },

  components: {
    ProductExtraSingle,
  },

  data() {
    return {
      productAmount: 0,
      addedExtras: 0,
      //Me dice si llegue al tope de los adicionales o gusto que puedo meter
      flavorLimit: false,
    };
  },

  mounted() {
    this.$store.commit("currentProduct/SET_SHOULD_RESET_OPTIONALS", false);
  },

  computed: {
    getOptionsText() {
      //Me devuelve el texto que tengo que poner en el aviso
      if (this.currentProduct?.hasToEnableFlavorLimit) {
        //Obtengo los limites del producto
        var min = this.currentProduct.minimumFlavorLimit;
        var max = this.currentProduct.maximumFlavorLimit;

        // Obtiene el nombre del grupo de opciones seleccionado
        var groupName = this.currentProductSelectedOptionsGroup.name;

        if (this.flavorLimit == false) {
          if (min == max)
            return `Elije ${min} ${
              groupName === "Adicionales"
                ? min === 1
                  ? "adicional"
                  : "adicionales"
                : min === 1
                ? "gusto"
                : "gustos"
            }`;

          if (min == 0 && max != 99)
            return `Elije hasta ${max} ${
              groupName === "Adicionales"
                ? max === 1
                  ? "adicional"
                  : "adicionales"
                : max === 1
                ? "gusto"
                : "gustos"
            }`;

          if (min != 0)
            return `Elige entre ${min} y ${max} ${
              groupName === "Adicionales"
                ? max === 1
                  ? "adicional"
                  : "adicionales"
                : max === 1
                ? "gusto"
                : "gustos"
            }`;
        }
      }
      return "";
    },
    title() {
      return this.$route.meta.title;
    },
    currentProductSelectedOptionsGroup() {
      if (
        this.currentProduct &&
        this.currentProduct.optionsGroups &&
        typeof this.$route.params.optionsGroupsIndex !== "undefined"
      ) {
        return this.currentProduct.optionsGroups[
          this.$route.params.optionsGroupsIndex
        ];
      }
      return undefined;
    },
    disableMore() {
      return false;
    },
  },

  watch: {
    currentAddedProduct: {
      handler() {
        if (this.hasAddedProduct) {
          this.initializeAddedProduct();
        }
      },
      immediate: true,
    },
    "$store.state.currentProduct": {
      //Estoy atento a cualquier cambio en el producto actual, lo hago en un watch porque el objeto puede ser modificado por otro componente
      handler() {
        this.updateProductOptionsQty();
      },
      deep: true,
    },
  },

  methods: {
    updateProductOptionsQty() {
      if (this.currentProduct?.hasToEnableFlavorLimit) {
        //Veo si llegue al tope y en ese caso la pongo true
        this.flavorLimit =
          this.optionalQty == this.currentProduct.maximumFlavorLimit
            ? true
            : false;
      }
    },
    getCurrentProductQuantity(extra) {
      const currentExtra =
        this.$store.state.currentProduct &&
        this.$store.state.currentProduct.options
          ? this.$store.state.currentProduct.options.find(
              (option) => option.sku === extra.sku
            )
          : undefined;

      if (currentExtra) {
        return currentExtra.quantity;
      }

      return 0;
    },
  },
};
</script>

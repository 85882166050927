<template>
  <v-col class="px-0" cols="12">
    <v-row class="px-2 extra-container">
      <v-col
        class="font-weight-medium text-subtitile-1 text-black text-lighten-2"
        cols="7"
      >
        <div>{{ extra.name }}</div>
        <div class="font-weight-regular stock-text">
          {{ hasReachedMaxStock || hasNegativeStock ? " (Stock Agotado)" : "" }}
        </div>
      </v-col>
      <v-col cols="5" class="secondary rounded-xl px-5 chip">
        <v-row justify="space-between" align="center" class="py-2">
          <v-btn
            :disabled="optionQuantity <= extra.minQty"
            @click="
              optionQuantity--;
              $store.commit('currentProduct/REMOVE_OPTION', {
                name: extra.name,
                unitPrice: extra.price,
                sku: extra.sku,
                quantity: optionQuantity,
                optionsGroupsName: optionsGroupsName,
              });
            "
            icon
            fab
            x-small
            elevation="0"
          >
            <v-icon color="black">mdi-minus</v-icon>
          </v-btn>
          <div
            class="px-1 text-h6"
            :class="
              optionQuantity <= 0
                ? 'grey--text lighten-2 font-weight-regular'
                : 'font-weight-medium'
            "
          >
            {{ optionQuantity }}
          </div>

          <v-btn
            :disabled="
              optionQuantity >= extra.maxQty ||
              disableMore ||
              hasToDisableButton ||
              hasReachedMaxStock ||
              hasNegativeStock
            "
            color="black"
            @click="
              optionQuantity++;
              $store.commit('currentProduct/ADD_OPTION', {
                name: extra.name,
                unitPrice: extra.price,
                sku: extra.sku,
                quantity: optionQuantity,
                optionsGroupsName: optionsGroupsName,
              });
            "
            icon
            fab
            x-small
            elevation="0"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
    <v-divider v-if="showDivider" class="px-0 mt-8" />
  </v-col>
</template>
<script>
import getCurrentProduct from "@/mixins/getCurrentProduct";
import stock from "@/mixins/stock";
export default {
  mixins: [getCurrentProduct, stock],
  data() {
    return {
      optionQuantity: 0,
    };
  },
  props: {
    optionsGroupsName: {
      type: String,
    },
    extra: {
      type: Object,
    },
    showDivider: {
      type: Boolean,
      default: true,
    },
    disableMore: {
      type: Boolean,
      default: false,
    },
    existingOptionQuantity: {
      type: Number,
      default: 0,
    },
  },
  mounted() {
    if (this.existingOptionQuantity > 0) {
      this.optionQuantity = this.existingOptionQuantity;
    } else {
      this.optionQuantity = this.extra.minQty;
    }
  },
  computed: {
    hasToDisableButton() {
      var disableButton = false; //Variable que voy a devolver en true o en false dependiendo si tengo o no que mostrar el boton
      disableButton =
        this.optionalQty == (this.currentProduct?.maximumFlavorLimit ?? 99); //Si mi cantidad llego al maximo o a 99 pongo en true
      return disableButton;
    },
    hasReachedMaxStock() {
      if (this.isStockValidationEnabled == false) return false;

      var listOfStock = this.$store.state.menu.profile?.listOfStocks; //Me traigo la lista de stock que pedi del back
      var optionInList = listOfStock?.find(
        (item) => item.sku === this.extra.sku
      ); //Encuentro la option
      if (optionInList == null) return false;

      if (this.optionsGroupsName == "Adicionales" && optionInList?.stock > 0) {
        return this.existingOptionQuantity >= optionInList?.stock;
      }

      return false;
    },
    hasNegativeStock() {
      if (this.isStockValidationEnabled == false) return false;

      var listOfStock = this.$store.state.menu.profile?.listOfStocks; //Me traigo la lista de stock que pedi del back
      var optionInList = listOfStock?.find(
        (item) => item.sku === this.extra.sku
      ); //Encuentro la option
      if (optionInList == null) return false;

      if (this.optionsGroupsName == "Adicionales" && optionInList?.stock <= 0)
        return true;

      return false;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .theme--light.v-divider {
  border-color: rgba(0, 0, 0, 0.5);
}

.extra-container {
  align-items: center;
}

.stock-text {
  font-size: 13px;
}
</style>